import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import NotFound from './components/NotFound.vue'
import Login from './views/Login.vue'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import AreaView from './views/mms/area/AreaView'
import MedicalForm from './views/mms/medical/MedicalForm'
import MedicalView from './views/mms/medical/MedicalView'
Vue.use(Router)

//https://stackoverflow.com/questions/64298276/load-dynamic-vue-routes-from-json-file
//https://router.vuejs.org/api/#router-addroutes

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    }, {
      path: '/login',
      name: 'login',
      component: Login
    },{
      path: '/area',
      name: 'area',
      component: () =>  AreaView //import('./views/mms/area/AreaView')
    },{
      path: '/brand',
      name: 'brand',
      component: () => import('./views/mms/brand/BrandView')
    },{
      path: '/category',
      name: 'category',
      component: () => import('./views/mms/category/CategoryView')
    },{
      path: '/itemgroup',
      name: 'itemgroup',
      component: () => import('./views/mms/itemgroup/ItemgroupView')
    },{
      path: '/newmedical',
      name: 'newmedical',
      component: () =>  MedicalForm //import('./views/mms/medical/MedicalForm')
    },{
      path: '/openmedicallist',
      name: 'openmedicallist',
      component: () => MedicalView //import('./views/mms/medical/MedicalView')
    },{
      path: '/newdistributor',
      name: 'newdistributor',
      component: () => import('./views/mms/distributor/DistributorForm')
    },{
      path: '/opendistributorlist',
      name: 'opendistributorlist',
      component: () => import('./views/mms/distributor/DistributorView')
    },{
      path: '/newdoctor',
      name: 'newdoctor',
      component: () => import('./views/mms/doctor/DoctorForm')
    },{
      path: '/opendoctorlist',
      name: 'opendoctorlist',
      component: () => import('./views/mms/doctor/DoctorView')
    },{
      path: '/newmedicine',
      name: 'newmedicine',
      component: () => import('./views/mms/medicine/MedicineForm')
    },{
      path: '/openmedicinelist',
      name: 'openmedicinelist',
      component: () => import('./views/mms/medicine/MedicineView')
    },{
      path: '/tourplan',
      name: 'tourplan',
      component: () => import('./views/mms/tourplan/TourPlanForm')
    },{
      path: '/dayplan',
      name: 'dayplan',
      component: () => import('./views/mms/dayplan/DayPlanForm')
    }, {
      path: '/greetings',
      name: 'greetings',
      component: () => import('./views/mms/greetings/Greetings')
    }, {
      path: '/remindercall',
      name: 'remindercall',
      component: () => import('./views/mms/remindercall/ReminderCall')
    }, {
      path: '/doctorreporting',
      name: 'doctorreporting',
      component: () => import('./views/mms/reporting/DoctorReporting')
    }, {
      path: '/medicalreporting',
      name: 'medicalreporting',
      component: () => import('./views/mms/reporting/MeicalReporting')
    }, {
      path: '/hr-designation',
      name: 'hr-designation',
      component: () => import('./views/hr/designation/DesignationView.vue')
    }, {
      path: '/hr-emp',
      name: 'hr-emp',
      component: () => import('./views/hr/employee/EmployeeView.vue')
    }, {
      path: '/companies',
      name: 'companies',
      component: () => import('./views/master/company/CompanyView.vue')
    }, {
      path: '/users',
      name: 'users',
      component: () => import('./views/master/users/UserView')
    },{
      path: '/calendar',
      name: 'calendar',
      component: () => import('./views/hr/holidays/YearCalendar')
    },{
      path: '/*',
      name: 'not found',
      component: NotFound,
    }
  ],
  base: '/',
  fallback: true,
})
