export const userService = {
    authHeader,
    handleResponse,
    isloggedIn,
    login,
    logout
};

function authHeader() {

  const self = this;
  // return authorization header with basic auth credentials

  let token = localStorage.getItem('biomicstoken');

  return {
    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE',
    'Access-Control-Allow-Headers' : '*',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token ,
  };
  // return { "Access-Control-Allow-Headers" : "Origin, X-Requested-With, Content-Type, Accept", 'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE', 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Basic xxxxx.*****.#####' , 'token' : token };
}



function handleResponse(resp) {

    // if (! _.isNull(resp.headers.get("token")) ){
    //   localStorage.setItem('tmstoken', resp.headers.get("token"));
    // }
    switch (resp.status) {
      case 100:
        return Promise.reject(new Error('Continue'));
        break;
      case 101:
        return Promise.reject(new Error('Switching Protocols'));
        break;
      case 102:
        return Promise.reject(new Error('Processing'));
        break;
      case 200:
        // this.$router.push({ path: '/login' });
        return resp.json();
        break;
      case 201:
        return Promise.reject(new Error('Created'));
        break;
      case 202:
        return Promise.reject(new Error('Accepted'));
        break;
      case 203:
        return Promise.reject(new Error('Non-Authoritative Information'));
        break;
      case 204:
        return Promise.reject(new Error('No Content'));
        break;
      case 205:
        return Promise.reject(new Error('Reset Content'));
        break;
      case 206:
        return Promise.reject(new Error('Partial Convent'));
        break;
      case 300:
        return Promise.reject(new Error('Multiple Choices'));
        break;
      case 301:
        return Promise.reject(new Error('Moved Permanently'));
        break;
      case 302:
        return Promise.reject(new Error('Found'));
        break;
      case 303:
        return Promise.reject(new Error('See Other'));
        break
      case 304:
        return Promise.reject(new Error('Not Modified'));
        break;
      case 305:
        return Promise.reject(new Error('Use Proxy'));
        break;
      case 306:
        return Promise.reject(new Error('Unused'));
        break;
      case 307:
        return Promise.reject(new Error('Temporary Redirect'));
        break;
      case 308:
        return Promise.reject(new Error('Permanent Redirect'));
        break;
      case 400:
        return Promise.reject(new Error('Bad Request'));
        break;
      case 401:

        //window.location = 'login';
        return Promise.reject(new Error('Invalid User Name & Password'));
        break;
      case 402:
        return Promise.reject(new Error('Payment Required'));
        break;
      case 403:
        return Promise.reject(new Error('Forbidden'));
        break;
      case 404:
        return Promise.reject(new Error('Not Found'));
        break;
      case 405:
        return Promise.reject(new Error('Method Not Allowed'));
        break;
      case 406:
        return Promise.reject(new Error('Not Acceptable'));
        break;
      case 407:
        return Promise.reject(new Error('Proxy Authentication Required'));
        break;
      case 408:
        return Promise.reject(new Error('Request Timeout'));
        break;
      case 409:
        return Promise.reject(new Error('Conflict'));
        break;
      case 410:
        return Promise.reject(new Error('Gone'));
        break;
      case 411:
        return Promise.reject(new Error('Length Required'));
        break;
      case 412:
        return Promise.reject(new Error('Precondition Failed'));
        break;
      case 413:
        return Promise.reject(new Error('Request Entity Too Large'));
        break;
      case 414:
        return Promise.reject(new Error('Request-URI Too Long'));
        break;
      case 415:
        return Promise.reject(new Error('Unsupported Media Type'));
        break;
      case 416:
        return Promise.reject(new Error('Requested Range Not Satisfiable'));
        break;
      case 417:
        return Promise.reject(new Error('Expectation Failed'));
        break;
      case 418:
        return Promise.reject(new Error('I\'m teapot'));
        break;
      case 419:
        return Promise.reject(new Error('Page Expired'));
        break;
      case 422:
        return Promise.reject(new Error('Unprocessable Entity'));
        break;
      case 428:
        return Promise.reject(new Error('Precondition Required'));
        break;
      case 429:
        return Promise.reject(new Error('Too Many Requests'));
        break;
      case 431:
        return Promise.reject(new Error('Request Header Fields Too Large'));
        break;
      case 451:
        return Promise.reject(new Error('Unavailable For Legal Reasons'));
        break;
      case 500:
        return Promise.reject(new Error('Internal Server Error'));
        break;
      case 501:
        return Promise.reject(new Error('Not Implemented'));
        break;
      case 502:
        return Promise.reject(new Error('Bad Gateway'));
        break;
      case 503:
        return Promise.reject(new Error('Service Unavailable'));
        break;
      case 504:
        return Promise.reject(new Error('Gateway Timeout'));
        break;
      case 505:
        return Promise.reject(new Error('HTTP Version Not Supported'));
        break;
      case 511:
        return Promise.reject(new Error('Network Authentication Required'));
        break;
      case 520:
        return Promise.reject(new Error('Web server is returning an unknown error'));
        break;
      case 522:
        return Promise.reject(new Error('Connection timed out'));
        break;
      case 524:
        return Promise.reject(new Error('A timeout occurred'));
        break;
      default:
        const err = (resp && resp.message) || resp.statusText;
        return Promise.reject(new Error(err));
    }
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        mode:"cors",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${process.env.VUE_APP_ROOT_API}api/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(username + ':' + password);
                localStorage.setItem('biomicsuser', JSON.stringify(user));
                localStorage.setItem('authdata', window.btoa(username + ':' + password));
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('biomicsuser');
    localStorage.removeItem('biomicstoken');
    localStorage.removeItem('branch_id');
    localStorage.removeItem('finyear');
    $cookies.remove('biomicsuser');
    $cookies.remove('biomicstoken');
}


function isloggedIn() {
    let result = localStorage.getItem('biomicstoken');
    return !_.isNull(result);
}
